import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { BaseState } from "./types";
import { RootState, ApiEmpty } from "../types";

export const state: BaseState = {
  pilotRegistration: new ApiEmpty(),
  questionRegistration: new ApiEmpty(),
  informationRequest: new ApiEmpty(),
};

const namespaced: boolean = true;

export const base: Module<BaseState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
