import Vue from "vue";
import Vuex from "vuex";

import ConfigService from "@/services/ConfigService";

// Development Loggers
import createLogger from "vuex/dist/logger";
import actionLogger from "./ActionLogger";

import { base } from "./base";
import { contents } from "./contents";
import { mentors } from "./mentors";
import { authorization } from "./authorization";
import { usergroups } from "./usergroups";

Vue.use(Vuex);

import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { RootState } from "./types";

// IMPORTANT: state must be a function so the module can be
// instantiated multiple times
export const state: () => RootState = () => ({
  createdAt: Date.now(),
  responseStatus: 200,
});

export function createStore() {
  const isServer = new ConfigService().config.isServer;
  const store = new Vuex.Store<RootState>({
    state,
    getters,
    actions,
    mutations,
    strict: !ConfigService.isProduction(),
    modules: {
      base,
      mentors,
      contents,
      authorization,
      usergroups,
    },
    plugins: ConfigService.isProduction() || isServer ? [] : [createLogger()],
  });

  if (!ConfigService.isProduction() && !isServer) {
    store.subscribeAction(actionLogger);
  }

  if (module.hot) {
    module.hot.accept(["@/store/mentors"], () => {
      Vue.prototype.$log.warn("HOT RELOADING...");

      const mentors = require("@/store/mentors").mentors;
      const usergroups = require("@/store/usergroups").usergroups;

      store.hotUpdate({
        modules: {
          base,
          mentors,
          contents,
          authorization,
          usergroups,
        },
      });
    });
  }

  return store;
}
