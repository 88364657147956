import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { UsergroupState } from "./types";
import { RootState, ApiEmpty } from "../types";

export const state: UsergroupState = {
  usergroup: new ApiEmpty(),
};

const namespaced: boolean = true;

export const usergroups: Module<UsergroupState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
