import { GetterTree } from "vuex";
import { AuthorizationState, AuthorizationGetterTypes } from "./types";
import { RootState, ApiRequest } from "../types";
import { User } from "@/models";
import { HttpServiceConfig } from "@/services/HttpService";

export const getters: GetterTree<AuthorizationState, RootState> = {
  [AuthorizationGetterTypes.CurrentUser](state): ApiRequest<User> {
    return state.currentUser;
  },
  [AuthorizationGetterTypes.Config](state): HttpServiceConfig {
    const token = state.currentUser.value && state.currentUser.value.token;
    return token ? { headers: { Authorization: `Bearer ${token}` } } : {};
  },
};
