import Vue from "vue";
import { ActionTree } from "vuex";

import { RootState, RootActionTypes, RootMutationTypes } from "./types";

export const actions: ActionTree<RootState, RootState> = {
  async [RootActionTypes.SetResponseStatus]({ commit }, status: number) {
    commit(RootMutationTypes.SetResponseStatus, status);
  },
};
