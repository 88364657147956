export interface RootState {
  createdAt: any;
  responseStatus: number;
  route?: any;
}

export const RootGetterTypes = {
  ResponseStatus: "ResponseStatus",
};

export const RootActionTypes = {
  SetResponseStatus: "SetResponseStatus",
};

export const RootMutationTypes = {
  SetResponseStatus: "SetResponseStatus",
};

////////////////////////////////////////////////////////////////////////////////

export class ApiEmpty {
  public loading = false;
  public empty = true;
  public value = null;
  public error = null;
}

export class ApiLoading {
  public loading = true;
  public empty = false;
  public value = null;
  public error = null;
}

export class ApiResult<T> {
  public loading = false;
  public empty = false;
  constructor(public value: T) {}
  public error = null;
}

export class ApiError {
  public loading = false;
  public empty = false;
  public value = null;
  constructor(public message: string, public error?: Object) {}
}

export type ApiRequest<T> = ApiEmpty | ApiLoading | ApiResult<T> | ApiError;
