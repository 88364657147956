import Vue from "vue";
import Router from "vue-router";

import { routes } from "./routes";
import { PositionResult } from "vue-router/types/router";
Vue.use(Router);

export function toSlug(str) {
  let slug = str.replace(/^\s+|\s+$/g, "").toLowerCase(); // trim and lowercase

  // remove accents, swap ñ for n, etc
  var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
  var to = "aaaaaeeeeeiiiiooooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    slug = slug.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  slug = slug
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return slug;
}

const scrollBehavior = function(to, from, savedPosition) {
  if (savedPosition) {
    // savedPosition is only available for popstate navigations.
    return savedPosition;
  } else {
    const position: PositionResult = { selector: null };

    // scroll to anchor by returning the selector
    if (to.hash) {
      position.selector = to.hash;
      return position;
    }

    return new Promise(resolve => {
      // check if any matched route config has meta that requires scrollTo item
      const matchedRoute = to.matched.find(m => m.meta.scrollTo);
      if (matchedRoute) {
        position.selector = matchedRoute.meta.scrollTo;
      }
    });
  }
};

export function createRouter() {
  const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior,
    routes,
  });
  return router;
}
