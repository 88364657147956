import { imagePath } from "./utils";

export interface Content {
  id: string;
  name: string;
  body: string;
  imageSrc: string;
}

export function apiContentToContent(api: any): Content {
  const { id, name, body, image } = api;

  return {
    id,
    name,
    body,
    imageSrc: imagePath(image),
  };
}
