import {
  Timeslot,
  MentorCompetence,
  apiMentorCompetenceToMentorCompetence,
} from "@/models";

export interface Subject {
  id: string;
  name: string;
}
export interface Industry {
  id: string;
  name: string;
}

export interface Mentor {
  id: string;
  appointments?: Timeslot[];
  availability?: { startTime: string; endTime: string }[];
  contactMethods: string[];
  familyName: string;
  filterWeight?: number;
  givenName: string;
  headerImageSrc: string;
  imageSrc: string;
  jobTitle: string;
  mentorCompetences: MentorCompetence[];
  profile: string;
  quote: string;
  rate: number;
  rating: number;
  reviews: number;
  sessions: number;
  slug: string;
}

import { imagePath } from "./utils";
import { toSlug } from "@/router";

export function apiMentorToMentor(apiMentor: any): Mentor {
  const {
    id,
    availabilities,
    contactmethods,
    familyName,
    givenName,
    headerImage,
    image,
    jobTitle,
    mentorcompetences,
    profile,
    quote,
    rate,
  } = apiMentor;

  const imageSrc = imagePath(image);
  const headerImageSrc = imagePath(headerImage);

  return {
    id: id,
    availability: availabilities && availabilities.length ? availabilities : [],
    contactMethods: contactmethods
      ? contactmethods
          .sort((a, b) => a.order - b.order)
          .map(method => method.name)
      : [],
    familyName,
    givenName,
    headerImageSrc,
    imageSrc,
    jobTitle,
    mentorCompetences: mentorcompetences
      ? mentorcompetences.map(m => apiMentorCompetenceToMentorCompetence(m))
      : [],
    profile,
    quote,
    rate,
    rating: 0,
    reviews: 0,
    sessions: 0,
    slug: toSlug(`${givenName} ${familyName}`),
  };
}
