import {
  Credentials,
  User,
  apiUserToUser,
  credentialsToApiCredentials,
} from "../models";

import { httpService } from "./HttpService";

const USER_NAME_KEY = "user_name";
const USER_TOKEN_KEY = "user_token";

export default {
  login: (credentials: Credentials): Promise<User> => {
    return httpService
      .post<User>("/auth/local", credentialsToApiCredentials(credentials))
      .then(user => apiUserToUser(user));
  },
  loadUser: (): User => {
    const result = {
      name: localStorage.getItem(USER_NAME_KEY),
      token: localStorage.getItem(USER_TOKEN_KEY),
    };
    return result.name && result.token ? result : null;
  },
  saveUser: (user: User): void => {
    localStorage.setItem(USER_NAME_KEY, user.name);
    localStorage.setItem(USER_TOKEN_KEY, user.token);
  },
};
