import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// This plugin ensures that we only load the icons that we explicitly declare

// free
import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons";
import {
  faStar as faStarSolid,
  faBars,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

// pro
import { faArrowAltRight } from "@fortawesome/pro-solid-svg-icons";
import {
  faPiggyBank,
  faComment,
  faFlagAlt,
  faUserFriends,
  faPhone,
} from "@fortawesome/pro-light-svg-icons";

// brands
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

// add to library
library.add(
  faTimes,
  faBars,
  faStarRegular,
  faStarSolid,
  faArrowAltRight,
  faPiggyBank,
  faComment,
  faFlagAlt,
  faUserFriends,
  faPhone,
  faWhatsapp,
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
