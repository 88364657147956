import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { AuthorizationState } from "./types";
import { RootState, ApiEmpty } from "../types";

export const state: AuthorizationState = {
  currentUser: new ApiEmpty(),
};

const namespaced: boolean = true;

export const authorization: Module<AuthorizationState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
