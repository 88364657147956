import { ApiRequest } from "@/store/types";
import {
  PilotRegistration,
  QuestionRegistration,
  InformationRequest,
} from "@/models";

export interface BaseState {
  pilotRegistration: ApiRequest<PilotRegistration>;
  questionRegistration: ApiRequest<QuestionRegistration>;
  informationRequest: ApiRequest<InformationRequest>;
}

export const BaseGetterTypes = {
  QuestionRegistration: "QuestionRegistration",
  PilotRegistration: "PilotRegistration",
  InformationRequest: "InformationRequest",
};

export const BaseActionTypes = {
  RegisterPilot: "RegisterPilot",
  ClearPilot: "ClearPilot",
  RegisterQuestion: "RegisterQuestion",
  RegisterInformationRequest: "RegisterInformationRequest",
  ClearInformationRequest: "ClearInformationRequest",
};

export const BaseMutationTypes = {
  SetPilotRegistration: "SetPilotRegistration",
  SetQuestionRegistration: "SetQuestionRegistration",
  SetInformationRequest: "SetInformationRequest",
};
