import Vue from "vue";
import { ActionTree } from "vuex";
import {
  RootState,
  ApiResult,
  ApiError,
  ApiLoading,
  ApiEmpty,
} from "@/store/types";

import {
  UsergroupState,
  UsergroupActionTypes,
  UsergroupMutationTypes,
} from "./types";
import { Usergroup, UsergroupType } from "@/models";

import UsergroupService from "@/services/UsergroupService";

export const actions: ActionTree<UsergroupState, RootState> = {
  async [UsergroupActionTypes.LoadUsergroup](
    { commit },
    { usergroupType, slug },
  ) {
    try {
      commit(UsergroupMutationTypes.SetUsergroup, new ApiLoading());
      const data = await UsergroupService.loadUsergroup(usergroupType, slug);
      commit(
        UsergroupMutationTypes.SetUsergroup,
        new ApiResult<Usergroup>(data),
      );
    } catch (error) {
      Vue.prototype.$log.error("LoadUsergroup error:", error);
      commit(
        UsergroupMutationTypes.SetUsergroup,
        new ApiError(error.message, error),
      );
    }
  },

  async [UsergroupActionTypes.ClearUsergroup]({ commit }) {
    commit(UsergroupMutationTypes.SetUsergroup, new ApiEmpty());
  },
};
