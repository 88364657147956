import Vue from "vue";
import { ActionTree } from "vuex";
import {
  RootState,
  ApiResult,
  ApiError,
  ApiLoading,
  ApiEmpty,
} from "@/store/types";

import {
  ContentState,
  ContentActionTypes,
  ContentMutationTypes,
} from "./types";
import { Content, BlogPost } from "@/models";

import ContentService from "@/services/ContentService";
import { RootActionTypes } from "@/store/types";

export const actions: ActionTree<ContentState, RootState> = {
  [ContentActionTypes.AcceptCookies]({ commit }, payload: boolean) {
    commit(ContentMutationTypes.SetCookieStatus, payload);
  },

  async [ContentActionTypes.LoadContents](
    { commit, dispatch, rootState },
    name: string,
  ) {
    try {
      commit(ContentMutationTypes.SetContents, {
        name,
        contents: new ApiLoading(),
      });
      const data = await ContentService.loadComponentContents(name);
      commit(ContentMutationTypes.SetContents, {
        name,
        contents: new ApiResult<Content[]>(data),
      });
    } catch (error) {
      Vue.prototype.$log.error("LoadContents error:", { error });
      if (!error.notFound) {
        // not a 404 error
        commit(ContentMutationTypes.SetContents, {
          name,
          contents: new ApiError(error.message, error),
        });
        return;
      }
      // resturn empty result when cms query string is present
      if (rootState.route.query.cms) {
        Vue.prototype.$log.warn(
          "LoadContents cms is true; rendering placeholders",
        );
        commit(ContentMutationTypes.SetContents, {
          name,
          contents: new ApiResult<Content[]>([]),
        });
      } else {
        commit(ContentMutationTypes.SetContents, {
          name,
          contents: new ApiError(error.message, error),
        });
        dispatch(RootActionTypes.SetResponseStatus, 404, {
          root: true,
        });
      }
    }
  },

  async [ContentActionTypes.LoadBlogPost]({ commit, dispatch }, id: string) {
    try {
      commit(ContentMutationTypes.SetBlogPost, new ApiLoading());
      const data = await ContentService.loadBlogPost(id);
      commit(ContentMutationTypes.SetBlogPost, new ApiResult<BlogPost>(data));
    } catch (error) {
      Vue.prototype.$log.error("LoadBlogPost error:", { error });
      commit(
        ContentMutationTypes.SetBlogPost,
        new ApiError(error.message, error),
      );
      if (error.notFound) {
        dispatch(RootActionTypes.SetResponseStatus, 404, {
          root: true,
        });
      }
    }
  },

  async [ContentActionTypes.ClearBlogPost]({ commit }) {
    commit(ContentMutationTypes.SetBlogPost, new ApiEmpty());
  },

  async [ContentActionTypes.LoadBlogPosts]({ commit, dispatch }) {
    try {
      commit(ContentMutationTypes.SetBlogPosts, new ApiLoading());
      const data = await ContentService.loadBlogPosts();
      commit(
        ContentMutationTypes.SetBlogPosts,
        new ApiResult<BlogPost[]>(data),
      );
    } catch (error) {
      Vue.prototype.$log.error("LoadBlogPosts error:", { error });
      commit(
        ContentMutationTypes.SetBlogPosts,
        new ApiError(error.message, error),
      );
    }
  },
};
