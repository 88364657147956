


























































import { Component, Vue } from "vue-property-decorator";

import Facebook from "@/assets/facebook.svg";
import LinkedIn from "@/assets/linkedin.svg";
import YouTube from "@/assets/youtube-18.svg";

import NavLink from "@/components/NavLinkComponent.vue";

interface sitePage {
  title: string;
  to: any;
}

interface siteSection {
  title: string;
  pages: sitePage[];
}

interface siteNavigation {
  sections: siteSection[];
}

import { mixins } from "vue-class-component";
import ContentComponent from "./ContentComponent";
import VueMarkdown from "vue-markdown";

@Component({
  components: { Facebook, LinkedIn, YouTube, VueMarkdown, NavLink },
})
export default class Footer extends mixins(ContentComponent) {
  constructor() {
    super();
    this.componentName = "footer";
  }

  get active() {
    return this.$store.state.route.path;
  }

  structure: siteNavigation = {
    sections: [
      {
        title: "Mentoroplossingen voor",
        pages: [
          {
            title: "Bedrijven",
            to: { name: "for-companies-nl", hash: "#details" },
          },
          {
            title: "Evenementen",
            to: { name: "for-events-nl", hash: "#details" },
          },
          {
            title: "Een-op-een",
            to: { name: "for-one-on-one-nl", hash: "#details" },
          },
          {
            title: "Vakverenigingen",
            to: { name: "for-trade-organisations-nl", hash: "#details" },
          },
          {
            title: "Je netwerk",
            to: { name: "for-your-network-nl", hash: "#details" },
          },
        ],
      },
      {
        title: "50 minutes with",
        pages: [
          {
            title: "Privacy",
            to: {
              name: "privacy-nl",
            },
          },
          {
            title: "Contact",
            to: {
              name: "contact-nl",
            },
          },
          {
            title: "Over ons",
            to: {
              name: "about-us-nl",
            },
          },
          {
            title: "Algemene voorwaarden",
            to: {
              name: "terms-and-conditions-nl",
            },
          },
          {
            title: "Tarieven",
            to: {
              name: "rates-nl",
            },
          },
        ],
      },
      {
        title: "Bron van kennis",
        pages: [
          {
            title: "Voor mentoren",
            to: {
              name: "for-mentors-nl",
            },
          },
          {
            title: "Voor mentees",
            to: {
              name: "for-mentees-nl",
            },
          },
          { title: "Blog", to: { name: "blog-nl" } },
        ],
      },
      {
        title: "Bron van groei",
        pages: [
          { title: "Word mentor", to: { name: "intake-mentor" } },
          { title: "Word mentee", to: { name: "intake-mentee" } },
          { title: "Word partner", to: { name: "for-whom-nl" } },
          { title: "Zoek mentor", to: { name: "mentor-overview-nl" } },
        ],
      },
    ],
  };
}
