import { MutationTree } from "vuex";
import { BaseState, BaseMutationTypes } from "./types";
import {
  QuestionRegistration,
  PilotRegistration,
  InformationRequest,
} from "@/models";
import { ApiRequest } from "../types";

export const mutations: MutationTree<BaseState> = {
  [BaseMutationTypes.SetPilotRegistration](
    state: BaseState,
    payload: ApiRequest<PilotRegistration>,
  ) {
    state.pilotRegistration = payload;
  },

  [BaseMutationTypes.SetQuestionRegistration](
    state: BaseState,
    payload: ApiRequest<QuestionRegistration>,
  ) {
    state.questionRegistration = payload;
  },

  [BaseMutationTypes.SetInformationRequest](
    state: BaseState,
    payload: ApiRequest<InformationRequest>,
  ) {
    state.informationRequest = payload;
  },
};
