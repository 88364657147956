export interface Credentials {
  identifier: string;
  password: string;
}

export function credentialsToApiCredentials(credentials: Credentials): any {
  return {
    identifier: credentials.identifier,
    password: credentials.password,
  };
}
