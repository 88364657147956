import { GetterTree } from "vuex";
import { BaseState, BaseGetterTypes } from "./types";
import { RootState, ApiRequest } from "../types";
import {
  QuestionRegistration,
  PilotRegistration,
  InformationRequest,
} from "@/models";

export const getters: GetterTree<BaseState, RootState> = {
  [BaseGetterTypes.PilotRegistration](state): ApiRequest<PilotRegistration> {
    return state.pilotRegistration;
  },

  [BaseGetterTypes.QuestionRegistration](
    state,
  ): ApiRequest<QuestionRegistration> {
    return state.questionRegistration;
  },

  [BaseGetterTypes.InformationRequest](state): ApiRequest<InformationRequest> {
    return state.informationRequest;
  },
};
