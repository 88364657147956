import { MutationTree } from "vuex";
import { AuthorizationState, AuthorizationMutationTypes } from "./types";
import { ApiRequest } from "../types";
import { User } from "@/models";

export const mutations: MutationTree<AuthorizationState> = {
  [AuthorizationMutationTypes.SetCurrentUser](
    state: AuthorizationState,
    payload: ApiRequest<User>,
  ) {
    state.currentUser = payload;
  },
};
