import Vue from "vue";
import { ActionTree } from "vuex";
import {
  RootState,
  ApiResult,
  ApiError,
  ApiLoading,
  ApiEmpty,
} from "@/store/types";
import {
  AuthorizationState,
  AuthorizationActionTypes,
  AuthorizationMutationTypes,
} from "./types";
import { User, Credentials } from "@/models";

import UserService from "@/services/UserService";

export const actions: ActionTree<AuthorizationState, RootState> = {
  async [AuthorizationActionTypes.Login]({ commit }, payload: Credentials) {
    try {
      commit(AuthorizationMutationTypes.SetCurrentUser, new ApiLoading());
      const data = await UserService.login(payload);
      UserService.saveUser(data);
      commit(
        AuthorizationMutationTypes.SetCurrentUser,
        new ApiResult<User>(data),
      );
    } catch (error) {
      Vue.prototype.$log.error("Login error:", error);
      commit(
        AuthorizationMutationTypes.SetCurrentUser,
        new ApiError(error.message, error),
      );
    }
  },
  async [AuthorizationActionTypes.LoadUser]({ commit }) {
    try {
      const data = UserService.loadUser();
      commit(
        AuthorizationMutationTypes.SetCurrentUser,
        data ? new ApiResult<User>(data) : new ApiEmpty(),
      );
    } catch (error) {
      Vue.prototype.$log.error("Loading user error:", error);
      commit(
        AuthorizationMutationTypes.SetCurrentUser,
        new ApiError(error.message, error),
      );
    }
  },
};
