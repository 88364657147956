


















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import NavLink from "@/components/NavLinkComponent.vue";

import Logo from "@/assets/logo.svg";

@Component({ components: { Logo, NavLink } })
export default class Nav extends Vue {
  showMenu = false;

  get home() {
    if (this.$store.state.route.meta.beta) {
      return { name: "home-nl" };
    }
    return { name: "how-it-works-nl" };
  }

  get active() {
    return this.$store.state.route.path;
  }

  toggle() {
    this.showMenu = !this.showMenu;
  }

  @Watch("$route")
  routeChanged() {
    this.showMenu = false;
  }
}
