import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from "axios";
import ConfigService, { IConfig } from "@/services/ConfigService";
import Vue from "vue";

export interface HttpErrorParameters {
  message: string;
  severity?: string;
  error?: object;
  recoverable?: boolean;
  notFound?: boolean;
}

export class HttpServiceError extends Error {
  public severity?: string;
  public recoverable?: boolean;
  public notFound?: boolean;

  constructor(parameters: HttpErrorParameters) {
    super(parameters.message);
    this.severity = parameters.severity;
    this.recoverable = parameters.recoverable;
    this.notFound = parameters.notFound;
  }
}

export interface HttpServiceConfig extends AxiosRequestConfig {
  target?: string;
  recoverable?: string[];
}

export function firstOrThrow(api: any[]) {
  if (api[0]) {
    return api[0];
  }
  throw "Item not found";
}

export class HttpService {
  private config: IConfig;
  private service: AxiosInstance;

  constructor() {
    this.config = new ConfigService().config;

    this.service = axios.create({
      baseURL: this.config.apiUrl,
      timeout: 3000,
    });
  }

  request<T>(config: HttpServiceConfig = {}): Promise<T> {
    return this.service
      .request(config)
      .then(
        response => this.handleResponse<T>(response, config),
        response => this.handleError(response, config.url),
      );
  }

  get<T>(url: string, config?: HttpServiceConfig): Promise<T> {
    return this.service
      .get(url, config)
      .then(
        response => this.handleResponse<T>(response, config || {}),
        response => this.handleError(response, url),
      );
  }

  delete<T>(url: string, config?: HttpServiceConfig): Promise<T> {
    return this.service
      .delete(url, config)
      .then(
        response => this.handleResponse<T>(response, config || {}),
        response => this.handleError(response, url),
      );
  }

  head<T>(url: string, config?: HttpServiceConfig): Promise<T> {
    return this.service
      .head(url, config)
      .then(
        response => this.handleResponse<T>(response, config || {}),
        response => this.handleError(response, url),
      );
  }

  post<T>(url: string, data?: any, config?: HttpServiceConfig): Promise<T> {
    return this.service
      .post(url, data, config)
      .then(
        response => this.handleResponse<T>(response, config || {}),
        response => this.handleError(response, url),
      );
  }

  put<T>(url: string, data?: any, config?: HttpServiceConfig): Promise<T> {
    return this.service
      .put(url, data, config)
      .then(
        response => this.handleResponse<T>(response, config || {}),
        response => this.handleError(response, url),
      );
  }

  patch<T>(url: string, data?: any, config?: HttpServiceConfig): Promise<T> {
    return this.service
      .patch(url, data, config)
      .then(
        response => this.handleResponse<T>(response, config || {}),
        response => this.handleError(response, url),
      );
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////

  private getValue(response: AxiosResponse, target: string = "") {
    if (!target) {
      return response.data;
    }
    return response.data && response.data[target];
  }

  private handleResponse<T>(
    response: AxiosResponse<T>,
    config: HttpServiceConfig,
  ) {
    Vue.prototype.$log.debug("HTTP success ", response);
    const value = this.getValue(response, config.target);
    if (value) {
      const { error_severity, error_message } = value;
      if (error_severity || error_message) {
        const canRecover = (config.recoverable || []).some(rec => value[rec]);
        throw new HttpServiceError({
          message: error_message,
          severity: error_severity,
          recoverable: canRecover,
        });
      }
    }
    return value as T;
  }

  private handleError(error: any, url: string) {
    if (error.response) {
      // The request was made and the server responded with a status code not 2xx
      Vue.prototype.$log.error("HTTP error (response) ", {
        status: error.response.status,
        url,
      });
      return Promise.reject(
        new HttpServiceError({
          message: `Error ${error.response.status} for ${url}`,
          error: error.response,
          notFound: error.response.status == 404,
        }),
      );
    }
    if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest
      if (error.code == "ECONNABORTED") {
        Vue.prototype.$log.debug(
          "HTTP error (API timeout) ",
          error.request.timeout,
          "ms",
          { url },
        );
        return Promise.reject(
          new HttpServiceError({
            message: `API timeout for ${url}`,
            error: error.request,
          }),
        );
      }
      Vue.prototype.$log.error("HTTP error (API error) ", {
        code: error.code,
        url,
      });
      return Promise.reject(
        new HttpServiceError({
          message: `API error ${error.code} for ${url}`,
          error: error.request,
        }),
      );
    }
    // Something happened in setting up the request that triggered an Error
    Vue.prototype.$log.error("HTTP error (message) ", error.message);
    return Promise.reject(
      new HttpServiceError({
        message: `Error ${error.message} for ${url}`,
        error,
      }),
    );
  }
}

export const httpService = new HttpService();
