import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { MentorState } from "./types";
import { RootState, ApiEmpty } from "../types";

export const state: MentorState = {
  mentor: new ApiEmpty(),
  mentorRegistration: new ApiEmpty(),
  mentors: new ApiEmpty(),
  filterOptions: new ApiEmpty(),
  setScheduleAppointment: new ApiEmpty(),
};

const namespaced: boolean = true;

export const mentors: Module<MentorState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
