import { Vue, Component, Watch } from "vue-property-decorator";

import { namespace, Getter, Action } from "vuex-class";
const contentsModule = namespace("contents");
const baseModule = namespace("base");

import { ApiRequest } from "@/store/types";
import { ContentActionTypes, ContentGetterTypes } from "@/store/contents/types";
import { RootGetterTypes, RootActionTypes } from "@/store/types";
import { Content } from "models";

@Component
export default class ContentComponent extends Vue {
  componentName: string;

  @contentsModule.Action(ContentActionTypes.LoadContents)
  LoadContents!: (name: string) => void;

  @contentsModule.Getter(ContentGetterTypes.Contents)
  contents!: (name: string) => ApiRequest<Content[]>;

  @contentsModule.Getter(ContentGetterTypes.Content)
  getContent!: (componentName: string, name: string) => Content;

  @Action(RootActionTypes.SetResponseStatus)
  SetResponseStatus!: (status: number) => void;

  @Getter(RootGetterTypes.ResponseStatus)
  responseStatus!: number;

  cms: boolean = false;

  getContentImage(name: string) {
    if (this.cms) {
      return `${this.componentName}: ${name}`;
    }
    const content = this.getContent(this.componentName, name);
    return content ? content.imageSrc : name;
  }

  getContentImageForComponent(componentName: string, name: string) {
    if (this.cms) {
      return `${this.componentName}: ${name}`;
    }
    const content = this.getContent(componentName, name);
    return content ? content.imageSrc : name;
  }

  getHeaderImage() {
    const content = this.getContent(
      this.componentName,
      `${this.componentName}-title`,
    );
    return content && content.imageSrc;
  }

  getContentBody(name: string) {
    if (this.cms) {
      return `${this.componentName}: ${name}`;
    }
    const content = this.getContent(this.componentName, name);
    return content ? content.body : name;
  }

  contentLoaded() {
    return (
      this.contents(this.componentName) &&
      this.contents(this.componentName).value
    );
  }

  contentEmpty() {
    return (
      !this.contents(this.componentName) ||
      this.contents(this.componentName).empty
    );
  }

  // Server-side only
  // This will be called by the server renderer automatically
  async serverPrefetch() {
    // return the Promise from the action
    // so that the component waits before rendering
    return this.LoadContents(this.componentName);
  }

  mounted() {
    this.routeChanged();
  }

  @Watch("$route")
  routeChanged() {
    if (this.componentName && !this.contentLoaded()) {
      this.LoadContents(this.componentName);
    }
    if (this.$store.state.route.query.cms) {
      this.cms = true;
    }
  }
}
