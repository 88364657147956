import Vue from "vue";
import { MutationTree } from "vuex";
import { ContentState, ContentMutationTypes } from "./types";
import { Content, BlogPost } from "@/models";
import { ApiRequest } from "../types";

export const mutations: MutationTree<ContentState> = {
  [ContentMutationTypes.SetCookieStatus](
    state: ContentState,
    payload: boolean,
  ) {
    state.cookieStatus = payload;
  },
  [ContentMutationTypes.SetContents](
    state: ContentState,
    payload: { name: string; contents: ApiRequest<Content[]> },
  ) {
    Vue.set(state.contents, payload.name, payload.contents);
  },
  [ContentMutationTypes.SetBlogPost](
    state: ContentState,
    payload: ApiRequest<BlogPost>,
  ) {
    state.blogPost = payload;
  },
  [ContentMutationTypes.SetBlogPosts](
    state: ContentState,
    payload: ApiRequest<BlogPost[]>,
  ) {
    state.blogPosts = payload;
  },
};
