import { ApiRequest } from "@/store/types";
import { Usergroup } from "@/models";

export interface UsergroupState {
  usergroup: ApiRequest<Usergroup>;
}

export const UsergroupGetterTypes = {
  Usergroup: "Usergroup",
};

export const UsergroupActionTypes = {
  LoadUsergroup: "LoadUsergroup",
  ClearUsergroup: "ClearUsergroup",
};

export const UsergroupMutationTypes = {
  SetUsergroup: "SetUsergroup",
};
