import { ApiRequest } from "@/store/types";
import {
  Mentor,
  FilterOptions,
  ScheduleAppointment,
  MentorRegistration,
} from "@/models";

export interface MentorState {
  mentor: ApiRequest<Mentor>;
  mentorRegistration: ApiRequest<MentorRegistration>;
  mentors: ApiRequest<Mentor[]>;
  filterOptions: ApiRequest<FilterOptions>;
  setScheduleAppointment: ApiRequest<ScheduleAppointment>;
}

export const MentorGetterTypes = {
  Mentors: "Mentors",
  Mentor: "Mentor",
  MentorRegistration: "MentorRegistration",
  FromMentors: "FromMentors",
  FilterOptions: "FilterOptions",
  Appointment: "Appointment",
};

export const MentorActionTypes = {
  LoadMentors: "LoadMentors",
  LoadAvailability: "LoadAvailability",
  LoadMentor: "LoadMentor",
  ClearMentor: "ClearMentor",
  LoadFilteredMentors: "LoadFilteredMentors",
  LoadFilterOptions: "LoadFilterOptions",
  ScheduleAppointment: "ScheduleAppointment",
  ClearAppointment: "ClearAppointment",
  RegisterMentor: "RegisterMentor",
};

export const MentorMutationTypes = {
  SetMentor: "SetMentor",
  SetMentorRegistration: "SetMentorRegistration",
  SetAvailability: "SetAvailability",
  SetMentors: "SetMentors",
  SetFilterOptions: "SetFilterOptions",
  SetScheduleAppointment: "SetScheduleAppointment",
};
