import { MutationTree } from "vuex";
import { MentorState, MentorMutationTypes } from "./types";
import {
  Mentor,
  FilterOptions,
  ScheduleAppointment,
  QuestionRegistration,
  MentorRegistration,
} from "@/models";
import { ApiRequest } from "../types";

export const mutations: MutationTree<MentorState> = {
  [MentorMutationTypes.SetMentors](
    state: MentorState,
    payload: ApiRequest<Mentor[]>,
  ) {
    state.mentors = payload;
  },

  [MentorMutationTypes.SetFilterOptions](
    state: MentorState,
    payload: ApiRequest<FilterOptions>,
  ) {
    state.filterOptions = payload;
  },

  [MentorMutationTypes.SetMentor](
    state: MentorState,
    payload: ApiRequest<Mentor>,
  ) {
    state.mentor = payload;
  },

  [MentorMutationTypes.SetMentorRegistration](
    state: MentorState,
    payload: ApiRequest<MentorRegistration>,
  ) {
    state.mentorRegistration = payload;
  },

  [MentorMutationTypes.SetAvailability](
    state: MentorState,
    payload: ApiRequest<Mentor>,
  ) {
    state.mentor.value.availability = payload.value.availability;
  },
  [MentorMutationTypes.SetScheduleAppointment](
    state: MentorState,
    payload: ApiRequest<ScheduleAppointment>,
  ) {
    state.setScheduleAppointment = payload;
  },
};
