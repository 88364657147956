import { DateTime } from "luxon";

export interface Timeslot {
  startTime: DateTime;
  endTime: DateTime;
}

export function apiTimeslotToTimeslot(api: any): Timeslot {
  return {
    startTime: DateTime.fromISO(api.startTime),
    endTime: DateTime.fromISO(api.endTime),
  };
}

export function timeslotToApiTimeslot(slot: Timeslot) {
  return {
    startTime: slot.startTime.toISO(),
    endTime: slot.endTime.toISO(),
  };
}
