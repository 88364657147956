export interface MentorCompetence {
  id: string;
  educations: string;
  expertise: string;
  expertiseLevel: string;
  industryId: string;
  jobs: string;
  mentorId: string;
  presentations: string;
  profile: string;
  publications: string;
  quote: string;
  references: string;
  subjectId: string;
  video: string;
  videos: string;
}

export function apiMentorCompetenceToMentorCompetence(
  apiMentorCompetence: any,
): MentorCompetence {
  const {
    id,
    educations,
    expertise,
    expertiseLevel,
    industry,
    jobs,
    mentor,
    presentations,
    profile,
    publications,
    quote,
    references,
    subject,
    video,
    videos,
  } = apiMentorCompetence;

  return {
    id,
    educations,
    expertise,
    expertiseLevel,
    industryId: industry,
    jobs,
    mentorId: mentor,
    presentations,
    profile,
    publications,
    quote,
    references,
    subjectId: subject,
    video,
    videos,
  };
}
