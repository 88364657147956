import BaseService from "./BaseService";
import { httpService } from "./HttpService";
import {
  FilterObject,
  apiMentorToMentor,
  timeslotToApiTimeslot,
  FilterOptions,
  apiScheduleAppointmentToScheduleAppointment,
  ScheduleAppointment,
  MentorRegistration,
  QuestionRegistration,
} from "@/models";

function filterMentor(keywords: string[], search: string[]) {
  return search.filter(v => keywords.includes(v)).length;
}

export default {
  loadMentors() {
    return httpService
      .get<any[]>("/public/mentors")
      .then(response => response.map(apiMentorToMentor));
  },

  loadMentor(id: string, forEvent: boolean) {
    return httpService
      .get<object>(`/public/mentors/${id}?forEvent=${forEvent}`)
      .then(apiMentorToMentor);
  },

  scheduleAppointment(appointment: ScheduleAppointment) {
    const { timeslot, reason } = appointment;
    const request = {
      ...appointment,
      timeslot: reason ? null : timeslotToApiTimeslot(timeslot),
    };
    return httpService
      .post<object>(
        `/public/mentors/${request.mentorId}/scheduleAppointment`,
        request,
      )
      .then(response => apiScheduleAppointmentToScheduleAppointment(response));
  },

  registerMentor(registration: MentorRegistration) {
    return httpService
      .post<object>("/public/mentors/register", registration)
      .then(response => response as MentorRegistration);
  },

  registerQuestion(question: QuestionRegistration) {
    return httpService
      .post<object>("/public/mentors/question", question)
      .then(response => response as QuestionRegistration);
  },

  loadFilterOptions() {
    const subjectPromise = httpService
      .get<any[]>("/public/subjects?_sort=name:ASC")
      .then(items =>
        items.map(m => {
          return { id: m.id, name: m.name };
        }),
      );
    const industryPromise = httpService
      .get<any[]>("/public/industries?_sort=name:ASC")
      .then(items =>
        items.map(m => {
          return { id: m.id, name: m.name };
        }),
      );
    return Promise.all([industryPromise, subjectPromise]).then(
      ([industries, subjects]) => {
        const filterOptions: FilterOptions = {
          levels: ["Specialist", "Expert", "Leader"],
          industries,
          subjects,
        };
        return filterOptions;
      },
    );
  },

  filterMentors(filter: FilterObject) {
    let subjectIds = filter.subjects ? filter.subjects.map(m => m.id) : [];
    let industryIds = filter.industries ? filter.industries.map(m => m.id) : [];

    return httpService
      .get<any[]>("/public/mentors")
      .then(response => response.map(apiMentorToMentor))
      .then(mentors =>
        mentors
          .map(mentor => {
            let filterWeight = 1;
            let mentorCompetences;

            // match op expertise level
            if (filter.level) {
              mentorCompetences = mentor.mentorCompetences.filter(
                x => x.expertiseLevel === filter.level,
              );
              filterWeight = mentorCompetences.length;
            } else {
              mentorCompetences = mentor.mentorCompetences;
            }

            // match en score op subjects en industries
            let subjectOrIndustryFiltered = false;
            let subjectsMatched = 0;
            if (filter.subjects && filter.subjects.length) {
              subjectsMatched = mentorCompetences.filter(m =>
                subjectIds.includes(m.subjectId),
              ).length;
              subjectOrIndustryFiltered = true;
            }

            let industriesMatched = 0;
            if (filter.industries && filter.industries.length) {
              industriesMatched = mentorCompetences.filter(m =>
                industryIds.includes(m.industryId),
              ).length;
              subjectOrIndustryFiltered = true;
            }

            if (subjectOrIndustryFiltered) {
              filterWeight = subjectsMatched + industriesMatched;
            }

            // console.warn("FILTER", mentor.givenName, filterWeight)

            return { ...mentor, filterWeight };
          })
          .filter(mentor => mentor.filterWeight)
          .sort((a, b) =>
            b.filterWeight == a.filterWeight
              ? 0.5 - Math.random()
              : b.filterWeight - a.filterWeight,
          ),
      );
  },
};
