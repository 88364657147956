// For some weird ununderstood reason we need to
// specify the extesion in the import below
import { createApp } from "@/app.ts";

// client-specific bootstrapping logic...

const { app, store, router } = createApp();

router.onReady(() => {
  if ((window as any).__INITIAL_STATE__) {
    // We initialize the store state with the data injected from the server
    store.replaceState((window as any).__INITIAL_STATE__);
  }

  // this assumes App.vue template root element has `id="app"`
  app.$mount("#app");
});

// it activates HMR and executes then webpack-dev-server will be run with hot property
if (module.hot) {
  const api = require("vue-hot-reload-api");
  const Vue = require("vue");

  api.install(Vue);
  if (!api.compatible) {
    throw new Error(
      "vue-hot-reload-api is not compatible with the version of Vue you are using.",
    );
  }

  module.hot.accept();
}
