export interface User {
  name: string;
  token: string;
}

export function apiUserToUser(api: any): User {
  return {
    name: api.user.username,
    token: api.jwt,
  };
}
