import { GetterTree } from "vuex";
import { ContentState, ContentGetterTypes } from "./types";
import { RootState, ApiRequest } from "../types";
import { Content, BlogPost } from "@/models";
import Vue from "vue";

export const getters: GetterTree<ContentState, RootState> = {
  [ContentGetterTypes.CookieStatus](state): boolean {
    return state.cookieStatus;
  },
  [ContentGetterTypes.Contents](
    state,
  ): (name: string) => ApiRequest<Content[]> {
    return (componentName: string) => {
      return state.contents[componentName];
    };
  },
  [ContentGetterTypes.Content](state) {
    return (componentName: string, name: string) => {
      if (
        state.contents[componentName] &&
        state.contents[componentName].value
      ) {
        const content = state.contents[componentName].value.find(
          pageBlock => pageBlock.name === name,
        );
        if (content) {
          return content;
        } else {
          Vue.prototype.$log.error(
            `could not find content for ${componentName}:${name}`,
          );
          return {
            body: `not found: ${componentName} - ${name}`,
            imageSource: "",
          };
        }
      }
    };
  },
  [ContentGetterTypes.BlogPost](state): ApiRequest<BlogPost> {
    return state.blogPost;
  },
  [ContentGetterTypes.BlogPosts](state): ApiRequest<BlogPost[]> {
    return state.blogPosts;
  },
};
