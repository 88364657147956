import _Vue from "vue";
import ConfigService from "@/services/ConfigService";

const logLevels = ["none", "error", "warn", "info", "debug"];
import Vue from "vue";

const Logger = {
  install(Vue: typeof _Vue, options?: any) {
    const config = new ConfigService().config;

    const logLevel = logLevels.indexOf(config.appLogLevel);

    Vue.prototype.$log = {
      log(...args: any[]) {
        if (logLevel > 2) {
          console.log(...args); // eslint-disable-line no-console
        }
      },
      debug(...args: any[]) {
        if (logLevel > 3) {
          console.debug("%cDebug", "color: #c93;", ...args); // eslint-disable-line no-console
        }
      },
      info(...args: any[]) {
        if (logLevel > 2) {
          console.info("%cInfo", "color: #f3c;", ...args); // eslint-disable-line no-console
        }
      },
      warn(...args: any[]) {
        if (logLevel > 1) {
          console.warn("%cWarn", "color: #fff; background: #00f;", ...args); // eslint-disable-line no-console
        }
      },
      error(...args: any[]) {
        if (logLevel > 0) {
          console.error("%cError", "color: #fff; background: #f00;", ...args); // eslint-disable-line no-console
        }
      },
    };
  },
};

Vue.use(Logger);
