
















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ components: {} })
export default class Nav extends Vue {
  @Prop({ required: true }) to: { name: string };
  @Prop({ required: true }) active: string;

  beta = false;
  href: string | null = null;

  mounted() {
    const route = this.$router.resolve(this.to).route;
    this.beta = (route && route.meta && route.meta.beta) || false;
    this.href = route.fullPath;
  }
}
