import Vue from "vue";
import { ActionTree } from "vuex";
import {
  RootState,
  ApiResult,
  ApiError,
  ApiLoading,
  ApiEmpty,
} from "@/store/types";

import { BaseState, BaseActionTypes, BaseMutationTypes } from "./types";
import {
  PilotRegistration,
  QuestionRegistration,
  InformationRequest,
} from "@/models";

import BaseService from "@/services/BaseService";

export const actions: ActionTree<BaseState, RootState> = {
  async [BaseActionTypes.RegisterPilot](
    { commit },
    registration: PilotRegistration,
  ) {
    try {
      commit(BaseMutationTypes.SetPilotRegistration, new ApiLoading());
      const data = await BaseService.registerPilot(registration);
      commit(
        BaseMutationTypes.SetPilotRegistration,
        new ApiResult<PilotRegistration>(data),
      );
    } catch (error) {
      commit(
        BaseMutationTypes.SetPilotRegistration,
        new ApiError(error.message, error),
      );
      Vue.prototype.$log.error("BaseService error:", error.message);
    }
  },

  async [BaseActionTypes.ClearPilot]({ commit }) {
    commit(BaseMutationTypes.SetPilotRegistration, new ApiEmpty());
  },

  async [BaseActionTypes.RegisterQuestion](
    { commit },
    question: QuestionRegistration,
  ) {
    try {
      commit(BaseMutationTypes.SetQuestionRegistration, new ApiLoading());
      const data = await BaseService.registerQuestion(question);
      commit(
        BaseMutationTypes.SetQuestionRegistration,
        new ApiResult<QuestionRegistration>(data),
      );
    } catch (error) {
      commit(
        BaseMutationTypes.SetQuestionRegistration,
        new ApiError(error.message, error),
      );
      Vue.prototype.$log.error("BaseService error:", error.message);
    }
  },

  async [BaseActionTypes.RegisterInformationRequest](
    { commit },
    request: InformationRequest,
  ) {
    try {
      commit(BaseMutationTypes.SetInformationRequest, new ApiLoading());
      const data = await BaseService.registerInformationRequest(request);
      commit(
        BaseMutationTypes.SetInformationRequest,
        new ApiResult<InformationRequest>(data),
      );
    } catch (error) {
      commit(
        BaseMutationTypes.SetInformationRequest,
        new ApiError(error.message, error),
      );
      Vue.prototype.$log.error("BaseService error:", error.message);
    }
  },

  async [BaseActionTypes.ClearInformationRequest]({ commit }) {
    commit(BaseMutationTypes.SetInformationRequest, new ApiEmpty());
  },
};
