import Vue from "vue";

interface iLogAction {
  type: string;
  payload: any;
}
export default (action: iLogAction) => {
  const { type, payload } = action;
  Vue.prototype.$log.log(
    "%caction " + type + " ",
    "color: #10769D; font-weight: bold;",
    payload || "",
  );
};
