import { MutationTree } from "vuex";
import { UsergroupState, UsergroupMutationTypes } from "./types";
import { Usergroup } from "@/models";
import { ApiRequest } from "../types";

export const mutations: MutationTree<UsergroupState> = {
  [UsergroupMutationTypes.SetUsergroup](
    state: UsergroupState,
    payload: ApiRequest<Usergroup>,
  ) {
    state.usergroup = payload;
  },
};
