import { GetterTree } from "vuex";
import { MentorState, MentorGetterTypes } from "./types";
import { RootState, ApiRequest } from "../types";
import {
  Mentor,
  FilterOptions,
  ScheduleAppointment,
  QuestionRegistration,
  MentorRegistration,
} from "@/models";

export const getters: GetterTree<MentorState, RootState> = {
  [MentorGetterTypes.Mentors](state): ApiRequest<Mentor[]> {
    return state.mentors;
  },

  [MentorGetterTypes.FilterOptions](state): ApiRequest<FilterOptions> {
    return state.filterOptions;
  },

  [MentorGetterTypes.Appointment](state): ApiRequest<ScheduleAppointment> {
    return state.setScheduleAppointment;
  },

  [MentorGetterTypes.MentorRegistration](
    state,
  ): ApiRequest<MentorRegistration> {
    return state.mentorRegistration;
  },

  [MentorGetterTypes.Mentor](state): ApiRequest<Mentor> {
    return state.mentor;
  },

  [MentorGetterTypes.FromMentors](state) {
    return (id: string) => {
      if (state.mentors.value) {
        return state.mentors.value!.find(mentor => mentor.id === id);
      }
    };
  },
};
