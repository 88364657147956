import ConfigService from "@/services/ConfigService";
import { Content } from "@/models";
const config = new ConfigService().config;

export function extractNames(apiSubjects: any[]): string[] {
  if (!apiSubjects || !apiSubjects.length) {
    return [];
  }
  return apiSubjects.map(subject => subject.name);
}

export function imagePath(image: { provider: string; url: string }): string {
  if (!image) {
    return "";
  }
  if (image.provider === "local") {
    return config.apiUrl + image.url;
  }
  return image.url;
}
