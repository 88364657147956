import { Content, apiBlogPostToBlogPost, apiContentToContent } from "@/models";

import { httpService, HttpServiceConfig } from "./HttpService";

export default {
  loadComponentContents(name: string) {
    return httpService.get<any>(`/components/${name}`).then(response => {
      return response.contents.map(apiContentToContent);
    });
  },
  loadBlogPosts() {
    return httpService
      .get<any[]>("/public/blogposts?_sort=publicationDate:desc")
      .then(posts => posts.map(apiBlogPostToBlogPost));
  },
  loadBlogPost(id: string) {
    return httpService
      .get<any[]>(`/public/blogposts/${id}`)
      .then(apiBlogPostToBlogPost);
  },
};
