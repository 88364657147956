export interface IConfig {
  env: string;
  apiUrl: string;
  appVersion: string;
  appDate: string;
  appLogLevel: string;
  isServer: boolean;
  googleTrackingId: string;
  hubspotTrackingId: string;
}

export default class {
  public readonly config: IConfig;

  constructor() {
    const isServer = typeof window === "undefined";
    this.config = {
      env: process.env.NODE_ENV,
      apiUrl: process.env.VUE_APP_API_URL,
      appVersion: process.env.VUE_APP_VERSION || "0.0.0 - 0",
      appDate: process.env.VUE_APP_DATE || "0000-00-00 00:00",
      appLogLevel:
        (isServer
          ? process.env.SERVER_LOG_LEVEL
          : process.env.VUE_APP_LOG_LEVEL) || "info",
      googleTrackingId: process.env.VUE_APP_GOOGLE_TRACKING_ID || "",
      hubspotTrackingId: process.env.VUE_APP_HUBSPOT_TRACKING_ID || "",
      isServer,
    };
  }

  public static isProduction() {
    return process.env.NODE_ENV === "production";
  }
}
