


































import { Component, Vue, Watch, Mixins } from "vue-property-decorator";
import { Meta } from "@sophosoft/vue-meta-decorator";

import { namespace, Getter } from "vuex-class";
const contentsModule = namespace("contents");
const baseModule = namespace("base");
import { ContentActionTypes, ContentGetterTypes } from "@/store/contents/types";
import { RootGetterTypes } from "@/store/types";

import { ApiRequest } from "@/store/types";
import { Content } from "@/models";
import ContentComponent from "./components/ContentComponent";
import VueMarkdown from "vue-markdown";
import Footer from "@/components/Footer.vue";
import NotFoundComponent from "@/components/NotFound.vue";
import Nav from "@/components/Nav.vue";

import VueCookieAcceptDecline from "vue-cookie-accept-decline";
import VueAnalytics from "vue-analytics";

import ConfigService from "@/services/ConfigService";

@Component({
  components: {
    NotFoundComponent,
    Nav,
    Footer,
    VueCookieAcceptDecline,
    VueMarkdown,
  },
})
export default class App extends Mixins(ContentComponent) {
  constructor() {
    super();
    this.componentName = "app";
  }

  @Getter(RootGetterTypes.ResponseStatus)
  responseStatus!: number;

  @contentsModule.Getter(ContentGetterTypes.CookieStatus)
  cookieStatus: boolean;

  @contentsModule.Action(ContentActionTypes.AcceptCookies)
  AcceptCookies: (accept: boolean) => Promise<void>;

  @Meta
  getMetaInfo() {
    const contents =
      this.contents(this.componentName) &&
      this.contents(this.componentName).value
        ? this.contents(this.componentName).value
        : [
            {
              name: "description",
              content: "50 Minutes With",
            },
          ];
    let meta = [];
    if (this.contents) {
      const blocks = contents.filter(contents =>
        contents.name.startsWith("meta"),
      );
      meta = blocks.map(block => {
        const name = block.name.substring(5);
        const content = block.body;
        return { name, content };
      });
    }
    return {
      title: "Homepage",
      titleTemplate: "50 Minutes With - %s",
      htmlAttrs: {
        lang: "nl",
        amp: false,
      },
      meta,
    };
  }

  mounted() {
    this.enableHotJar();
    this.enableHubspot();
  }

  @Watch("$route")
  routeChanged() {
    this.SetResponseStatus(200);
  }

  cookieClickedAccept() {
    this.AcceptCookies(true);
    this.enableFullAnalytics();
  }

  cookieClickedDecline() {
    this.AcceptCookies(false);
    this.enableAnonymousAnalytics();
  }

  // This event fires when the component is initialized. It receives the
  // current status from the component. Which stores it in localstorage BTW.
  // In the rest of the app we can check the cookie status via our store
  // property.
  setCookieStatus(statusText) {
    if (statusText !== null) {
      const status = statusText === "accept";
      this.AcceptCookies(status);
      if (status) {
        this.enableFullAnalytics();
      } else {
        this.enableAnonymousAnalytics();
      }
    }
  }

  enableHotJar() {
    (function(h?: any, o?: any, t?: any, j?: any, a?: any, r?: any) {
      h.hj =
        h.hj ||
        function() {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 1506621, hjsv: 6 };
      a = o.getElementsByTagName("head")[0];
      r = o.createElement("script");
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
  }

  enableHubspot() {
    // We only want to enable Hubspot on the client (not in SSR) and
    // and only if we have a tracking id.
    const config = new ConfigService().config;
    const { isServer, hubspotTrackingId } = config;
    if (!isServer && hubspotTrackingId) {
      var script = document.createElement("script");
      script.id = "hs-script-loader";
      script.src = `//js.hs-scripts.com/${hubspotTrackingId}.js`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
  }

  enableFullAnalytics() {
    // We only want to enable Google Analytics on the client (not in SSR) and
    // and only if we have a tracking id.
    const config = new ConfigService().config;
    const { isServer, googleTrackingId } = config;
    if (!isServer && googleTrackingId) {
      Vue.use(VueAnalytics, {
        id: googleTrackingId,
        router: this.$router,
      });
    }
  }

  enableAnonymousAnalytics() {
    // We only want to enable Google Analytics on the client (not in SSR) and
    // and only if we have a tracking id.
    const config = new ConfigService().config;
    const { isServer, googleTrackingId } = config;
    if (!isServer && googleTrackingId) {
      Vue.use(VueAnalytics, {
        id: googleTrackingId,
        router: this.$router,
        beforeFirstHit() {
          this.$ga.set("anonymizeIp", true);
        },
      });
    }
  }
}
