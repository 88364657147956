import Vue from "vue";

import App from "./App.vue";

import { createRouter } from "@/router";
import { createStore } from "@/store";
import { sync } from "vuex-router-sync";

Vue.config.productionTip = false;

// Plugins
import "@/plugins/icons";
import "@/plugins/logger";

import VueMeta from "vue-meta";
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

// Styles
import "@/styles/index.scss";

export function createApp() {
  // create router and store instances
  const router = createRouter();
  const store = createStore();

  // sync so that route state is available as part of the store
  sync(store, router);

  const app = new Vue({
    // inject router into root Vue instance
    router,
    store,
    render: h => h(App),
  });

  // return both the app and the router
  return {
    app,
    router,
    store,
  };
}
