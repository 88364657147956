import { User } from "@/models";
import { ApiRequest } from "../types";

export interface AuthorizationState {
  currentUser: ApiRequest<User>;
}

export const AuthorizationGetterTypes = {
  CurrentUser: "CurrentUser",
  Config: "Config",
};

export const AuthorizationMutationTypes = {
  SetCurrentUser: "SetCurrentUser",
};

export const AuthorizationActionTypes = {
  Login: "Login",
  LoadUser: "LoadUser",
};
