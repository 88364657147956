import { imagePath } from "./utils";
import { apiMentorToMentor } from "@/models";
import { DateTime } from "luxon";
import { toSlug } from "@/router";
import { Industry, Subject } from "./Mentor";

export interface BlogPost {
  id: string;
  slug: string;
  title: string;
  intro: string;
  body: string;
  image: string;
  publicationDate: DateTime;
  mentor?: { givenName: string; familyName: string; imageSrc: string };
  industries: Industry[];
  subjects: Subject[];
}

export function apiBlogPostToBlogPost(apiBlogPost: any): BlogPost {
  const {
    id,
    title,
    intro,
    body,
    mentor,
    industries,
    image,
    subjects,
    publicationDate,
  } = apiBlogPost;

  return {
    id,
    title,
    intro,
    body,
    publicationDate: DateTime.fromISO(publicationDate),
    image: imagePath(image),
    mentor: mentor ? apiMentorToMentor(mentor) : null,
    industries: industries,
    subjects: subjects,
    slug: toSlug(title),
  };
}
