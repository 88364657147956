import Vue from "vue";
import { UsergroupType } from "@/models";

// route level code-splitting
// this generates a separate chunk (blog.[hash].js) for this route
// which is lazy-loaded when the route is visited.
const Home = () => import(/* webpackChunkName: "base" */ "./views/Home.vue");
const NotFoundPage = () =>
  import(/* webpackChunkName: "base" */ "./views/NotFoundPage.vue");
const HowItWorks = () =>
  import(/* webpackChunkName: "base" */ "./views/HowItWorks.vue");
const ForWhom = () =>
  import(/* webpackChunkName: "base" */ "./views/ForWhom.vue");
const Pilot = () => import(/* webpackChunkName: "base" */ "./views/Pilot.vue");
const UsergroupPage = () =>
  import(/* webpackChunkName: "usergroups" */ "./views/UsergroupPage.vue");
const ContentPage = () =>
  import(/* webpackChunkName: "base" */ "./views/ContentPage.vue");
const OutgrowPage = () =>
  import(/* webpackChunkName: "outgrow" */ "./views/OutgrowPage.vue");
const BlogIndex = () =>
  import(/* webpackChunkName: "blog" */ "./views/BlogIndex.vue");
const BlogPage = () =>
  import(/* webpackChunkName: "blog" */ "./views/BlogPage.vue");
const MentorPage = () =>
  import(/* webpackChunkName: "mentor" */ "./views/MentorDetailPage.vue");
const MentorOverview = () =>
  import(/* webpackChunkName: "mentor" */ "./views/MentorOverview.vue");
const MentorRegistration = () =>
  import(/* webpackChunkName: "mentor" */ "./views/MentorRegistration.vue");
const QuestionRegistration = () =>
  import(/* webpackChunkName: "mentor" */ "./views/QuestionRegistration.vue");
const TODO = () =>
  import(/* webpackChunkName: "base" */ "./views/ContentPage.vue");
const MentorLogin = () =>
  import(/* webpackChunkName: "login" */ "./views/MentorLogin.vue");

// Vue.use(Router);

export const routes = [
  /* EN */
  {
    path: "/beta",
    name: "home-en",
    component: Home,
    meta: { beta: true },
  },
  {
    path: "/",
    alias: "/how-it-works",
    name: "how-it-works-en",
    component: HowItWorks,
  },
  // usergroup
  {
    path: "/event/:usergroupSlug",
    name: "usergroup-event-page-en",
    component: UsergroupPage,
    meta: { usergroupType: UsergroupType.event },
  },
  {
    path: "/company/:usergroupSlug",
    name: "usergroup-company-page-en",
    component: UsergroupPage,
    meta: { usergroupType: UsergroupType.company },
  },
  {
    path: "/association/:usergroupSlug",
    name: "usergroup-association-page-en",
    component: UsergroupPage,
    meta: { usergroupType: UsergroupType.association },
  },
  // usergroup mentor
  {
    path: "/event/:usergroupSlug/mentor/:slug/:id",
    name: "usergroup-event-mentor-page-en",
    component: MentorPage,
    meta: { usergroupType: UsergroupType.event },
  },
  {
    path: "/company/:usergroupSlug/mentor/:slug/:id",
    name: "usergroup-company-mentor-page-en",
    component: MentorPage,
    meta: { usergroupType: UsergroupType.company },
  },
  {
    path: "/association/:usergroupSlug/mentor/:slug/:id",
    name: "usergroup-association-mentor-page-en",
    component: MentorPage,
    meta: { usergroupType: UsergroupType.association },
  },
  //
  {
    path: "/for-whom",
    alias: "/50-minutes-with",
    name: "for-whom-en",
    component: ForWhom,
    meta: { for: "one-on-one" },
  },
  {
    path: "/pilot",
    name: "pilot-en",
    component: Pilot,
  },
  {
    path: "/blog",
    name: "blog-en",
    component: BlogIndex,
  },
  {
    path: "/blog/:slug?/:id",
    name: "blog-page-en",
    component: BlogPage,
  },
  {
    path: "/beta/mentee/question",
    name: "mentee-question-en",
    component: QuestionRegistration,
    meta: { beta: true },
  },
  {
    path: "/beta/mentor/signup",
    name: "mentor-signup-en",
    component: MentorRegistration,
    meta: { beta: true },
  },
  {
    path: "/beta/mentor/:slug?/:id",
    name: "mentor-page-en",
    component: MentorPage,
    meta: { beta: true },
  },
  {
    path: "/beta/mentor/",
    name: "mentor-overview-en",
    component: MentorOverview,
    meta: { beta: true },
  },
  {
    path: "/beta/login",
    name: "mentor-login-en",
    component: MentorLogin,
    meta: { beta: true },
  },
  {
    path: "/50-minutes-with/one-on-one",
    name: "for-one-on-one-en",
    component: ForWhom,
    meta: { for: "one-on-one", scrollTo: "details" },
  },
  {
    path: "/50-minutes-with/for-companies",
    name: "for-companies-en",
    component: ForWhom,
    meta: { for: "companies", scrollTo: "details" },
  },
  {
    path: "/50-minutes-with/for-events",
    name: "for-events-en",
    component: ForWhom,
    meta: { for: "events", scrollTo: "details" },
  },
  {
    path: "/50-minutes-with/for-trade-organisations",
    name: "for-trade-organisations-en",
    component: ForWhom,
    meta: { for: "trade-organisations", scrollTo: "details" },
  },
  {
    path: "/50-minutes-with/for-your-network",
    name: "for-your-network-en",
    component: ForWhom,
    meta: { for: "your-network", scrollTo: "details" },
  },
  {
    path: "/50-minutes-with/terms-and-conditions",
    name: "terms-and-conditions",
    component: ContentPage,
  },

  /* NL */
  // usergroup
  {
    path: "/evenement/:usergroupSlug",
    name: "usergroup-event-page-nl",
    component: UsergroupPage,
    meta: { usergroupType: UsergroupType.event },
  },
  {
    path: "/bedrijf/:usergroupSlug",
    name: "usergroup-company-page-nl",
    component: UsergroupPage,
    meta: { usergroupType: UsergroupType.company },
  },
  {
    path: "/vereniging/:usergroupSlug",
    name: "usergroup-association-page-nl",
    component: UsergroupPage,
    meta: { usergroupType: UsergroupType.association },
  },

  // usergroup mentor
  {
    path: "/event/:usergroupSlug/mentor/:slug/:id",
    name: "usergroup-event-mentor-page-nl",
    component: MentorPage,
    meta: { usergroupType: UsergroupType.event },
  },
  {
    path: "/bedrijf/:usergroupSlug/mentor/:slug/:id",
    name: "usergroup-company-mentor-page-nl",
    component: MentorPage,
    meta: { usergroupType: UsergroupType.company },
  },
  {
    path: "/vereniging/:usergroupSlug/mentor/:slug/:id",
    name: "usergroup-association-mentor-page-nl",
    component: MentorPage,
    meta: { usergroupType: UsergroupType.association },
  },

  //
  {
    path: "/beta",
    name: "home-nl",
    component: Home,
    meta: { beta: true },
  },
  {
    path: "/hoe-het-werkt",
    name: "how-it-works-nl",
    component: HowItWorks,
  },
  {
    path: "/voor-wie",
    name: "for-whom-nl",
    component: ForWhom,
    meta: { for: "one-on-one" },
  },
  {
    path: "/pilot",
    name: "pilot-nl",
    component: Pilot,
  },
  {
    path: "/blog",
    name: "blog-nl",
    component: BlogIndex,
  },
  {
    path: "/blog/:slug?/:id",
    name: "blog-page-nl",
    component: BlogPage,
  },
  {
    path: "/beta/mentee/question",
    name: "mentee-question-nl",
    component: QuestionRegistration,
    meta: { beta: true },
  },
  {
    path: "/beta/mentor/signup",
    name: "mentor-signup-nl",
    component: MentorRegistration,
    meta: { beta: true },
  },
  {
    path: "/beta/mentor/:slug?/:id",
    name: "mentor-page-nl",
    component: MentorPage,
    meta: { beta: true },
  },
  {
    path: "/beta/mentor/",
    name: "mentor-overview-nl",
    component: MentorOverview,
    meta: { beta: true },
  },
  {
    path: "/beta/login",
    name: "mentor-login-nl",
    component: MentorLogin,
    meta: { beta: true },
  },
  {
    path: "/50-minutes-with/een-op-een",
    name: "for-one-on-one-nl",
    component: ForWhom,
    meta: { for: "one-on-one", scrollTo: "details" },
  },
  {
    path: "/50-minutes-with/voor-bedrijven",
    name: "for-companies-nl",
    component: ForWhom,
    meta: { for: "companies", scrollTo: "details" },
  },
  {
    path: "/50-minutes-with/voor-evenementen",
    name: "for-events-nl",
    component: ForWhom,
    meta: { for: "events", scrollTo: "details" },
  },
  {
    path: "/50-minutes-with/voor-vakverenigingen",
    name: "for-trade-organisations-nl",
    component: ForWhom,
    meta: { for: "trade-organisations", scrollTo: "details" },
  },
  {
    path: "/50-minutes-with/je-netwerk",
    name: "for-your-network-nl",
    component: ForWhom,
    meta: { for: "your-network", scrollTo: "details" },
  },
  {
    path: "/50-minutes-with/algemene-voorwaarden",
    name: "terms-and-conditions-nl",
    component: ContentPage,
  },
  {
    path: "/50-minutes-with/over-ons",
    name: "about-us-nl",
    component: ContentPage,
  },
  {
    path: "/50-minutes-with/contact",
    name: "contact-nl",
    component: ContentPage,
  },
  {
    path: "/50-minutes-with/tarieven",
    name: "rates-nl",
    component: ContentPage,
  },
  {
    path: "/50-minutes-with/privacy",
    name: "privacy-nl",
    component: ContentPage,
  },
  {
    path: "/50-minutes-with/voor-mentees",
    name: "for-mentees-nl",
    component: ContentPage,
  },
  {
    path: "/50-minutes-with/voor-mentoren",
    name: "for-mentors-nl",
    component: ContentPage,
  },
  {
    path: "/intake-mentor",
    name: "intake-mentor",
    component: OutgrowPage,
    meta: { beta: false },
  },
  {
    path: "/intake-mentee",
    name: "intake-mentee",
    component: OutgrowPage,
    meta: { beta: false },
  },
  {
    path: "/intake-cug",
    name: "intake-cug",
    component: OutgrowPage,
    meta: { beta: false },
  },

  /* General (catchall should come last */
  // {
  //   path: "/beta/:section/:page",
  //   name: "beta-section-content",
  //   component: ContentPage,
  //   meta: { beta: true },
  // },
  // {
  //   path: "/:section/:page",
  //   name: "section-content",
  //   component: ContentPage,
  // },
  // {
  //   path: "/:page",
  //   name: "root-content",
  //   component: ContentPage,
  //   meta: { beta: true },
  // },
  // {
  //   path: "/beta/:page",
  //   name: "beta-root-content",
  //   component: ContentPage,
  //   meta: { beta: true },
  // },

  {
    path: "/not-found",
    name: "not-found-page",
    component: NotFoundPage,
    meta: { beta: false },
  },

  {
    path: "*",
    name: "not-found",
    component: NotFoundPage,
    meta: { beta: false },
  },
];
