import Vue from "vue";
import { ActionTree } from "vuex";
import {
  RootState,
  ApiResult,
  ApiError,
  ApiLoading,
  ApiEmpty,
} from "@/store/types";

import { MentorState, MentorActionTypes, MentorMutationTypes } from "./types";
import {
  Mentor,
  MentorRegistration,
  FilterObject,
  FilterOptions,
  ScheduleAppointment,
} from "@/models";

import MentorService from "@/services/MentorService";
import { RootActionTypes } from "@/store/types";

export const actions: ActionTree<MentorState, RootState> = {
  async [MentorActionTypes.LoadMentors]({ commit }) {
    try {
      commit(MentorMutationTypes.SetMentors, new ApiLoading());
      const data = await MentorService.loadMentors();
      commit(MentorMutationTypes.SetMentors, new ApiResult<Mentor[]>(data));
    } catch (error) {
      Vue.prototype.$log.error("LoadMentors error:", error);
      commit(
        MentorMutationTypes.SetMentors,
        new ApiError(error.message, error),
      );
    }
  },

  async [MentorActionTypes.LoadFilteredMentors](
    { commit },
    filter: FilterObject,
  ) {
    try {
      commit(MentorMutationTypes.SetMentors, new ApiLoading());
      const data = await MentorService.filterMentors(filter);
      commit(MentorMutationTypes.SetMentors, new ApiResult<Mentor[]>(data));
    } catch (error) {
      Vue.prototype.$log.error("SetMentors error:", error);
      commit(
        MentorMutationTypes.SetMentors,
        new ApiError(error.message, error),
      );
    }
  },

  async [MentorActionTypes.LoadFilterOptions]({ commit }) {
    try {
      commit(MentorMutationTypes.SetFilterOptions, new ApiLoading());
      const data = await MentorService.loadFilterOptions();
      commit(
        MentorMutationTypes.SetFilterOptions,
        new ApiResult<FilterOptions>(data),
      );
    } catch (error) {
      Vue.prototype.$log.error("LoadFilterOptions error:", error);
      commit(
        MentorMutationTypes.SetFilterOptions,
        new ApiError(error.message, error),
      );
    }
  },

  async [MentorActionTypes.LoadMentor]({ commit, dispatch }, { id, forEvent }) {
    try {
      commit(MentorMutationTypes.SetMentor, new ApiLoading());
      const data = await MentorService.loadMentor(id, forEvent);
      commit(MentorMutationTypes.SetMentor, new ApiResult<Mentor>(data));
    } catch (error) {
      Vue.prototype.$log.error("LoadMentor error:", error);
      commit(MentorMutationTypes.SetMentor, new ApiError(error.message, error));
      if (error.notFound) {
        dispatch(RootActionTypes.SetResponseStatus, 404, {
          root: true,
        });
      }
    }
  },

  async [MentorActionTypes.LoadAvailability]({ commit }, id: string) {
    try {
      const data = await MentorService.loadMentor(id, true);
      commit(MentorMutationTypes.SetAvailability, new ApiResult<Mentor>(data));
    } catch (error) {
      Vue.prototype.$log.error("LoadMentor error:", error);
      commit(MentorMutationTypes.SetMentor, new ApiError(error.message, error));
    }
  },

  async [MentorActionTypes.ClearMentor]({ commit }) {
    commit(MentorMutationTypes.SetMentor, new ApiEmpty());
  },

  async [MentorActionTypes.ScheduleAppointment](
    { commit, dispatch },
    scheduleAppointment: ScheduleAppointment,
  ) {
    try {
      commit(MentorMutationTypes.SetScheduleAppointment, new ApiLoading());
      const data = await MentorService.scheduleAppointment(scheduleAppointment);
      commit(
        MentorMutationTypes.SetScheduleAppointment,
        new ApiResult<ScheduleAppointment>(data),
      );
      dispatch(
        MentorActionTypes.LoadAvailability,
        scheduleAppointment.mentorId,
      );
    } catch (error) {
      commit(
        MentorMutationTypes.SetScheduleAppointment,
        new ApiError(error.message, error),
      );
      Vue.prototype.$log.error("MentorService error:", error.message);
    }
  },

  async [MentorActionTypes.ClearAppointment]({ commit }) {
    commit(MentorMutationTypes.SetScheduleAppointment, new ApiEmpty());
  },

  async [MentorActionTypes.RegisterMentor](
    { commit },
    registration: MentorRegistration,
  ) {
    try {
      commit(MentorMutationTypes.SetMentorRegistration, new ApiLoading());
      const data = await MentorService.registerMentor(registration);
      commit(
        MentorMutationTypes.SetMentorRegistration,
        new ApiResult<MentorRegistration>(data),
      );
    } catch (error) {
      commit(
        MentorMutationTypes.SetMentorRegistration,
        new ApiError(error.message, error),
      );
      Vue.prototype.$log.error("MentorService error:", error.message);
    }
  },
};
