import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { ContentState } from "./types";
import { RootState, ApiEmpty } from "../types";

export const state: () => ContentState = () => ({
  cookieStatus: false,
  contents: {},
  blogPost: new ApiEmpty(),
  blogPosts: new ApiEmpty(),
});

const namespaced: boolean = true;

export const contents: Module<ContentState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
