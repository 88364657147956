import { GetterTree } from "vuex";
import { UsergroupState, UsergroupGetterTypes } from "./types";
import { RootState, ApiRequest } from "../types";
import { Usergroup } from "@/models";

export const getters: GetterTree<UsergroupState, RootState> = {
  [UsergroupGetterTypes.Usergroup](state): ApiRequest<Usergroup> {
    return state.usergroup;
  },
};
