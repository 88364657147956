import { ApiRequest } from "@/store/types";
import { Content, BlogPost } from "@/models";

export interface ContentState {
  cookieStatus: boolean;
  contents: { [page: string]: ApiRequest<Content[]> };
  blogPost: ApiRequest<BlogPost>;
  blogPosts: ApiRequest<BlogPost[]>;
}

export const ContentGetterTypes = {
  CookieStatus: "CookieStatus",
  Contents: "Contents",
  Content: "Content",
  BlogPost: "BlogPost",
  BlogPosts: "BlogPosts",
};

export const ContentActionTypes = {
  AcceptCookies: "AcceptCookies",
  LoadContents: "LoadContents",
  LoadBlogPost: "LoadBlogPost",
  LoadBlogPosts: "LoadBlogPosts",
  ClearBlogPost: "ClearBlogPost",
};

export const ContentMutationTypes = {
  SetCookieStatus: "SetCookieStatus",
  SetContents: "SetContents",
  SetBlogPost: "SetBlogPost",
  SetBlogPosts: "SetBlogPosts",
};
