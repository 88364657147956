import { httpService } from "./HttpService";
import { apiUsergroupToUsergroup, UsergroupType } from "@/models";

export default {
  loadUsergroup(usergroupType: UsergroupType, slug: string) {
    return httpService
      .get<object>(`/public/usergroups/${slug}?usergroupType=${usergroupType}`)
      .then(apiUsergroupToUsergroup);
  },
};
