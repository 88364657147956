import { httpService } from "./HttpService";
import {
  PilotRegistration,
  QuestionRegistration,
  InformationRequest,
} from "@/models";

export default {
  registerPilot(registration: PilotRegistration) {
    return httpService
      .post<object>("/register", registration)
      .then(response => response as PilotRegistration);
  },

  registerQuestion(question: QuestionRegistration) {
    return httpService
      .post<object>("/public/mentors/question", question)
      .then(response => response as QuestionRegistration);
  },

  registerInformationRequest(request: InformationRequest) {
    return httpService
      .post<object>("/request-info", request)
      .then(response => response as InformationRequest);
  },
};
