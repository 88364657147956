export enum UsergroupType {
  "association" = "association",
  "company" = "company",
  "event" = "event",
}

export interface Usergroup {
  id: string;
  slug: string;
  title: string;
  imageSrc: string;
  intro?: string;
  body: string;
  publicationDate?: Date;
  expirationDate?: Date;
  mentors?: Mentor[];
  usergroupType: string;
}

import { imagePath } from "./utils";
import { Mentor, apiMentorToMentor } from "./Mentor";

export function apiUsergroupToUsergroup(apiUsergroup: any): Usergroup {
  const {
    id,
    slug,
    title,
    image,
    intro,
    body,
    publicationDate,
    expirationDate,
    mentors,
    usergroupType,
  } = apiUsergroup;

  const imageSrc = imagePath(image);

  return {
    id,
    slug,
    title,
    imageSrc,
    intro,
    body,
    publicationDate,
    expirationDate,
    mentors: mentors.map(x => apiMentorToMentor(x)),
    usergroupType,
  };
}
