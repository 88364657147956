import { Timeslot, apiTimeslotToTimeslot } from "./Timeslot";
import { DateTime } from "luxon";

export interface ScheduleAppointment {
  mentorId: string;
  name: string;
  question: string;
  email: string;
  phoneNumber: string;
  tool: string;
  toolDescription?: string;
  toolId: string;
  timeslot?: Timeslot;
  reason?: string;
  subscribeNewsletter?: boolean;
}

export function apiScheduleAppointmentToScheduleAppointment(
  api: any,
): ScheduleAppointment {
  const {
    mentorId,
    menteeName,
    menteeEmail,
    menteePhoneNumber,
    menteeQuestion,
    menteeTool,
    menteeToolDescription,
    menteeToolId,
    startTime,
    endTime,
    menteeReason,
  } = api;
  return {
    mentorId,
    name: menteeName,
    question: menteeQuestion,
    email: menteeEmail,
    phoneNumber: menteePhoneNumber,
    tool: menteeTool,
    toolDescription: menteeToolDescription,
    toolId: menteeToolId,
    reason: menteeReason,
    timeslot: menteeReason
      ? null
      : apiTimeslotToTimeslot({ startTime, endTime }),
  };
}
